export default (cb = () => {}, options = { doPost: true }) => {
  const { open: openImage, onChange } = useFileDialog({ accept: 'image/*', multiple: false })
  const { messageError } = useSwal()
  const { t } = useNuxtApp().$i18n
  const { doFetch } = useFetchApi()

  const imageBase64 = ref()

  onChange(async (file) => {
    if (file[0]) {
      if (file[0].size > 6 * 1024 * 1024) {
        messageError(t('user.fail'), t('user.overSize6mb'))
        return
      }

      const { execute } = useBase64(file[0])
      const base64 = await execute()

      imageBase64.value = base64

      await addImage(`${new Date().getTime()}_${file[0].name}`, base64, file[0].type)
    }
  })

  const addImage = async (filename, base64, filetype) => {
    const data = { filename, base64, filetype }
    let res
    if (options.doPost) {
      res = await postImage(data)
    } else {
      res = data
    }

    cb(res)
  }

  // fetch data
  const postImage = async (body) => {
    return await doFetch({
      type: 'image',
      method: 'POST',
      body,
    })
  }

  return {
    imageBase64,
    openImage,
    postImage,
  }
}
